// @see https://github.com/twbs/bootstrap/blob/main/scss/_functions.scss
@function material-icons-str-replace($string, $search, $replace: '') {
  $index: str-index($string, $search);
  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace +
      material-icons-str-replace(
        str-slice($string, $index + str-length($search)),
        $search,
        $replace
      );
  }
  @return $string;
}

@mixin material-icons-font-class($font-family) {
  font-family: $font-family;
  font-weight: normal;
  font-style: normal;
  font-size: $material-icons-font-size;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-smoothing: antialiased; // Support for all WebKit browsers
  -moz-osx-font-smoothing: grayscale; // Support for Firefox
  text-rendering: optimizeLegibility; // Support for Safari and Chrome
  font-feature-settings: 'liga'; // Support for IE
}

@mixin material-icons-font($font-family) {
  $class-name: to-lower-case($font-family);
  $class-name: material-icons-str-replace($class-name, ' ', '-');
  $font-file: $material-icons-font-path + $class-name;

  @font-face {
    font-family: $font-family;
    font-style: normal;
    font-weight: 400;
    font-display: $material-icons-font-display;
    src: url('#{$font-file}.woff2') format('woff2'),
      url('#{$font-file}.woff') format('woff');
  }

  .#{$class-name} {
    @include material-icons-font-class($font-family);
  }
}

@mixin material-icons() {
  @warn "material-icons() Sass mixin has been deprecated as of 1.0. Use '@extend .material-icons;' instead of '@include material-icons();'.";
  @include material-icons-font-class('Material Icons');
}
