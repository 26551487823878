$assets-path: '/assets/icons/yukawa' !default;
$spinner-color: white !default;
$logo-url: '/assets/icons/logo.svg' !default;
$logo-color-class: 'bg-white' !default;
$logo-width: 300px !default;
$logo-height: 150px !default;
$background-class: bg-primary !default;
$log-background-class: bg-primary-700 !default;
$log-header-background-class: bg-primary-800 !default;
$table-background-class: bg-primary-700 !default;
$text-class: text-primary-100 !default;

@mixin expand() {
    @keyframes yukawa-splash-screen-expand {
        0% {
            opacity: 0;
            height: 0;
        }
        100% {
            visibility: visible;
            opacity: 1;
            height: var(--extended-height);
        }
    }
    @-webkit-keyframes yukawa-splash-screen-expand {
        0% {
            opacity: 0;
            height: 0;
        }
        100% {
            visibility: visible;
            opacity: 1;
            height: var(--extended-height);
        }
    }
    $animation: yukawa-splash-screen-expand 1000ms cubic-bezier(0, 0, 0.2, 1);
    -webkit-animation: $animation;
    animation: $animation;
    transform: translate3d(0, 0, 0);
    will-change: contents, height;
}

@mixin collapse() {
    @keyframes yukawa-splash-screen-collapse {
        0% {
            visibility: visible;
            opacity: 1;
            height: var(--extended-height);
        }
        100% {
            opacity: 0;
            height: 0;
        }
    }
    @-webkit-keyframes yukawa-splash-screen-collapse {
        0% {
            visibility: visible;
            opacity: 1;
            height: var(--extended-height);
        }
        100% {
            opacity: 0;
            height: 0;
        }
    }
    $animation: yukawa-splash-screen-collapse 1000ms cubic-bezier(0, 0, 0.2, 1);
    -webkit-animation: $animation;
    animation: $animation;
    transform: translate3d(0, 0, 0);
    will-change: contents, height;
}

@mixin fadeIn() {
    @keyframes yukawa-splash-screen-fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            visibility: visible;
            opacity: 1;
        }
    }
    @-webkit-keyframes yukawa-splash-screen-fadeIn {
        0% {
            opacity: 0;
        }
        100% {
            visibility: visible;
            opacity: 1;
        }
    }
    $animation: yukawa-splash-screen-fadeIn 1000ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-animation: $animation;
    animation: $animation;
}

@mixin fadeOut() {
    @keyframes yukawa-splash-screen-fadeOut {
        0% {
            visibility: visible;
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    @-webkit-keyframes yukawa-splash-screen-fadeOut {
        0% {
            visibility: visible;
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
    $animation: yukawa-splash-screen-fadeOut 1000ms cubic-bezier(0.4, 0, 0.2, 1);
    -webkit-animation: $animation;
    animation: $animation;
}

@mixin mask($width, $height, $color-class, $url) {
    width: $width;
    height: $height;
    @apply #{$color-class};
    mask: url($url) no-repeat center;
    mask-size: contain;
    -webkit-mask: url($url) no-repeat center;
    -webkit-mask-size: contain;
}

yukawa-splash-screen {
    @apply fixed flex flex-col items-center justify-center #{$background-class};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999999;
    opacity: 1;
    visibility: visible;

    &,
    #app-log > * {
        @apply #{$text-class};
    }

    h1, h2, h3, h4, h5, h6,
    .h1, .h2, .h3, .h4, .h5, .h6 {
        @apply text-center #{$text-class};
    }

    #app-logo {
        @include mask($logo-width, $logo-height, $logo-color-class, $logo-url);

        &.loading {
            @include fadeIn();
        }

        img {
            @apply pointer-events-none;
            width: $logo-width;
            max-width: $logo-height;
        }
    }

    body.yukawa-splash-screen-hidden & {
        opacity: 0;
        visibility: hidden;
    }

    body.yukawa-splash-screen-hidden-animated & {
        @include fadeOut();
        visibility: hidden;
    }

    > svg:not(#spinner) {
        @apply hidden;
    }

    div#spinner {
        margin-top: 20px;
    }

    #spinner {
        @apply flex items-center justify-between;
        width: 56px;
        height: 56px;
        margin-top: 20px;
        color: $spinner-color;

        > div {
            width: 12px;
            height: 12px;
            background-color: $spinner-color;
            border-radius: 100%;
            display: inline-block;
            -webkit-animation: yukawa-splash-screen-bouncedelay 1s infinite ease-in-out both;
            animation: yukawa-splash-screen-bouncedelay 1s infinite ease-in-out both;
        }

        .bounce1 {
            animation-delay: -0.32s;
        }

        .bounce2 {
            animation-delay: -0.16s;
        }

        @keyframes yukawa-splash-screen-bouncedelay {
            0%, 80%, 100% {
                transform: scale(0);
            }
            40% {
                transform: scale(1.0);
            }
        }
        @-webkit-keyframes yukawa-splash-screen-bouncedelay {
            0%, 80%, 100% {
                transform: scale(0);
            }
            40% {
                transform: scale(1.0);
            }
        }

        &.hide {
            @include fadeOut();
            visibility: hidden;
        }
    }

    #app-log {
        @apply hidden;
        @apply text-sm sm:text-base;
        transition: height, max-height .5s;
        height: 0;
        --extended-height: 0px;

        &.show {
            @apply flex flex-col;
            @include expand();

            height: var(--extended-height);
            @apply max-w-4/5 sm:max-w-2/3 md:max-w-1/2;
            @apply max-h-3/5 sm:max-h-2/3 md:max-h-1/2;
        }

        &.hide {
            @include collapse();

            height: 0;
            visibility: hidden;
        }

        table {
            @apply #{$log-background-class};

            thead {
                @apply text-lg sm:text-xl #{$log-header-background-class};
                @apply border-b-2 border-primary-200 shadow;
            }

            tr {
                td {
                    @apply p-2 sm:p-4;
                }
            }

            tbody tr {
                @apply border-t border-b border-primary-200;

                &:first-child {
                    @apply border-t-0;
                }

                &:last-child {
                    @apply border-b-0;
                }

                td {
                    vertical-align: top;

                    &:nth-child(2) {
                        @apply font-mono whitespace-pre-wrap;
                    }
                }

                &.debug td:first-child {
                    color: palegoldenrod;
                }

                &.info td:first-child {
                    color: darkgray;
                }

                &.log td:first-child {
                    color: #fff;
                }

                &.warn td:first-child {
                    color: darkorange;
                }

                &.error td:first-child {
                    color: orangered;
                }
            }
        }
    }

    #actions {
        @apply hidden mt-4;

        &.show {
            @apply inline-block;
            @include fadeIn();
        }

        &.hide {
            @include collapse();
            height: 0;
            visibility: hidden;
        }

        svg {
            @apply w-10 h-10 #{$text-class} place-self-center;
        }

        button {
            > * {
                @apply place-self-center;
            }

            .label {
                @apply underline;
            }
        }
    }
}
