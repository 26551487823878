/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */

.invisible-tabs {
    >.mat-mdc-tab-header {
        display: none;
    }
}

@import './angular-material.scss';

mat-cell {
    border-bottom: none !important;
    border-bottom-width: 0;
}

mat-row.mat-mdc-row {
    border-bottom-width: 2px !important;
}