// YUKAWA COMPONENTS
@use "@yukawa/chain-base-angular-comp/assets/styles/splash-screen" with ($background-class: 'bg-black',
    $text-class: 'text-white'
);
@use "@yukawa/chain-base-angular-comp/assets/styles/confirmation-dialog";
@use "@yukawa/chain-base-angular-comp/assets/styles/query-table";

@import "@yukawa/chain-base-angular-comp/assets/styles/alert.component";

// FONTS 
@import "./fonts/fonts.scss";
/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */

/* Perfect scrollbar */
@import 'perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import 'quill/dist/quill.snow.css';