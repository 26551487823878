yukawa-query-table {

    mat-table {
        box-shadow: none;

        mat-header-cell {

            &:first-child {

                width: auto !important;
            }
        }

        mat-cell,
        mat-header-cell {
            flex: 1 0 100px;
            @apply px-2;
        }

        /**
            Align header cell content to right
            Usage: @extend .header-cell-align-right !optional;
         */
        .header-cell-align-right {
            .mat-sort-header-container {
                @apply flex flex-row-reverse gap-2;
            }

            .mat-sort-header-content {
                @apply text-right;
            }
        }

        @media only screen and (max-width: 760px), (min-device-width: 768px) and (max-device-width: 1024px) {
            .mat-cell:last-of-type {
                @apply pr-2;
            }
        }

        mat-header-row, mat-paginator {
            @apply bg-card dark:bg-card #{'!important'};
        }

        mat-row {
            min-height: 64px;
            @apply transition-all pt-2 pb-2;
            &.mat-row-active {
                @apply bg-primary;
                mat-cell {
                    @apply text-white;

                    span, mat-icon, &::before {
                        @apply text-white;
                    }
                }
            }
        }

        mat-row, mat-header-row {
            mat-cell, mat-header-cell {
                &.mat-column-expand {
                    flex: 0 0 50px;
                    @apply pl-2 pr-2;
                }
            }
        }
    }
}
