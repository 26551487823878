/* This injects Tailwind's base styles and any base styles registered by plugins. */
@tailwind base;

/* This injects additional styles into Tailwind's base styles layer. */
@layer base {

    * {
        /* Text rendering */
        text-rendering: optimizeLegibility;
        -o-text-rendering: optimizeLegibility;
        -ms-text-rendering: optimizeLegibility;
        -moz-text-rendering: optimizeLegibility;
        -webkit-text-rendering: optimizeLegibility;
        -webkit-tap-highlight-color: transparent;

        /* Remove the focus ring */
        &:focus {
            outline: none !important;
        }
    }

    /* HTML and Body default styles */
    html,
    body {
        display: flex;
        flex-direction: column;
        flex: 1 1 auto;
        width: 100%;
        min-height: 100%;
        -webkit-font-smoothing: auto;
        -moz-osx-font-smoothing: auto;
    }

    /* Font size */
    html {
        font-size: 16px;
    }

    body {
        font-size: 0.875rem;
    }

    /* Stylistic alternates for Inter */
    body {
        font-feature-settings: 'salt';
    }

    // MIGRATED THIS FROM OLD TAILWIND CONFIG
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    .h1,
    .h2,
    .h3,
    .h4,
    .h5,
    .h6,
    {
    @apply text-black;
    @apply dark:text-white;
}

h1,
h2,
h3,
.h1,
.h2,
.h3 {
    @apply font-bossa;
}

h4,
h5,
h6,
.h4,
.h5,
.h6 {
    @apply font-sans;
}

h1,
.h1 {
    @apply font-medium;
    @apply text-3xl;
    @apply leading-8;
}

h2,
.h2 {
    @apply text-2xl;
    @apply leading-7;
}

h3,
.h3 {
    @apply font-medium;
    @apply text-lg;
    @apply leading-5;
}

h4,
.h4 {
    @apply font-medium;
    @apply text-base;
    @apply leading-4;
}

h5,
.h5 {
    @apply font-medium;
    @apply text-sm;
    @apply leading-4;
}

p {
    @apply font-sans;
    @apply text-base;
    @apply leading-5;
}

// MIGRATE END

/* Better spacing and border for horizontal rule */
hr {
    margin: 32px 0;
    border-bottom-width: 1px;
}

/* Make images and videos to take up all the available space */
img {
    width: 100%;
    vertical-align: top;
}

/* Fix: Disabled placeholder color is too faded on Safari */
input[disabled] {
    opacity: 1;
    -webkit-text-fill-color: currentColor;
}

/* Set the background and foreground colors */
body,
.dark,
.light {
    @apply text-default bg-default #{'!important'};
}

/* Set the border color */
*,
::before,
::after {
    --tw-border-opacity: 1 !important;
    border-color: rgba(var(--fuse-border-rgb), var(--tw-border-opacity));

    .dark & {
        --tw-border-opacity: 0.12 !important;
    }
}

/* Style scrollbars on platforms other than MacOS and iOS */
@media only screen and (min-width: 960px) {

    body:not(.os-mac) {

        ::-webkit-scrollbar {
            width: 8px;
            height: 8px;
            background-color: rgba(0, 0, 0, 0);
        }

        ::-webkit-scrollbar:hover {
            width: 8px;
            height: 8px;
            background-color: rgba(0, 0, 0, 0.06);
        }

        ::-webkit-scrollbar-thumb {
            border: 2px solid transparent;
            border-radius: 20px;
            box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.24);
        }

        ::-webkit-scrollbar-thumb:active {
            border-radius: 20px;
            box-shadow: inset 0 0 0 20px rgba(0, 0, 0, 0.37);
        }

        &.dark {
            ::-webkit-scrollbar-thumb {
                box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.24);
            }

            ::-webkit-scrollbar-thumb:active {
                box-shadow: inset 0 0 0 20px rgba(255, 255, 255, 0.37);
            }
        }
    }
}

/* Set the foreground color for disabled elements */
[disabled] * {
    @apply text-disabled #{'!important'};
}

/* Print styles */
@media print {

    /* Make the base font size smaller for print so everything is scaled nicely */
    html {
        font-size: 12px !important;
    }

    body,
    .dark,
    .light {
        background: none !important;
    }
}
}

/* This injects Tailwind's component classes and any component classes registered by plugins. */
@tailwind components;