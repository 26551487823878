$font-prefix: '..' !default;
@font-face {
  font-family: 'IBM Plex Mono';
  font-style: normal;
  font-weight: 400;
  src: local('IBM Plex Mono'),
    local('IBMPlexMono'),
    url('#{$font-prefix}/IBM-Plex-Mono/fonts/complete/woff2/IBMPlexMono-Regular.woff2') format('woff2'),
    url('#{$font-prefix}/IBM-Plex-Mono/fonts/complete/woff/IBMPlexMono-Regular.woff') format('woff');
}

@import 'cyrillic';
@import 'pi';
@import 'latin3';
@import 'latin2';
@import 'latin1';